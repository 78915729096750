<template>
  <CSidebar 
    fixed 
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/"  style="text-decoration: none;">
      <div class="c-sidebar-brand-full">
        <CRow>
          <CIcon name="cil-blur" style="margin-top:7px; width: 30px !important; height: 30px !important;"/>
          <h1>SRI</h1>
          <CBadge color="warning" style="height: 15px; margin-top: 20px">Bank</CBadge>
        </CRow>
      </div>
      <CIcon 
        class="c-sidebar-brand-minimized" 
        name="cil-blur"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="$options.nav"/>
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from './_nav'

export default {
  name: 'TheSidebar',
  nav,
  computed: {
    show () {
      return this.$store.state.sidebarShow 
    },
    minimize () {
      return this.$store.state.sidebarMinimize 
    }
  }
}
</script>
