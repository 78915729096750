export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Cadastro']
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Cadastro',
        route: '/Cadastro',
        icon: 'cil-puzzle',
        items: [
          {
            name: 'Empresas',
            to: '/Cadastro/Empresas'
          },
          {
            name: 'Equipamentos',
            to: '/Cadastro/Equipamentos'
          },
        ]
      },
      {
        _name: 'CSidebarNavTitle',
        _children: ['Financeiro']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Conta Bancária',
        to: '/contabancaria',
        icon: 'cil-bank',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Transferência',
        to: '/tranferencia',
        icon: 'cil-transfer',
      },
      /*
      {
        _name: 'CSidebarNavItem',
        name: 'Transações',
        to: '/transacoes',
        icon: 'cil-money',
      },*/
      {
        _name: 'CSidebarNavDivider',
        _class: 'm-2'
      },      
    ]
  }
]