<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="user.png"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Conta de Usuário</strong>
    </CDropdownHeader>
    <CDropdownItem>
      <CIcon name="cil-envelope-open" /> Messagens
      <CBadge v-if="itemsCount>0" color="success" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem>
    <CDropdownHeader tag="div" class="text-center" color="light">
      <strong>Configurações</strong>
    </CDropdownHeader>
    <CDropdownItem href="#/alterarsenha">
      <CIcon name="cil-user" /> Alterar senha
    </CDropdownItem>
    <CDropdownDivider/>
    <CDropdownItem @click="Logoff">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 0
    }
  },
  methods:{
    Logoff: function(){
      localStorage.removeItem('user-token');
      localStorage.removeItem('user-data');
      this.$router.push('/admin/login');
      window.location.reload();
    } 
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>